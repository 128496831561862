<template>
  <div>
    <Header />
    <div class="holder">
      <div class="first-hero">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6" style="margin-bottom: 40px;">
              <div class="hero-left">
                <h1>Complete your project faster than ever!</h1>
                <p>
                  No more delay starting and completing a project. With SAFE by
                  Shelta, you can now fund your rent and any housing related
                  projects instantly.
                </p>
                <div class="hero-button">
                  <button class="btn">
                    <router-link to="/signup">
                      Get Started For Free
                    </router-link>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-6 -6 pr-sm-0 img-sm">
              <div class="hero-right">
                <img src="../assets/safe/safing.svg" alt="safe" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="project-choice">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-4" style="margin-bottom: 24px;">
              <div class="choice-header">
                <h2>Fund projects of your choice</h2>
              </div>
            </div>

            <div class="col-md-8">
              <div class="row">
                <div class="col-md-6">
                  <div class="choice">
                    <img src="../assets/icons/rent-safe.svg" alt="rent" />
                    <h6>Rent</h6>
                    <p>
                      In all sincerity, you won’t like your landlord coming to
                      knock on your door to pay your rent. To avoid this, start
                      funding immediately and qualify for gap financing.
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="choice">
                    <img
                      src="../assets/icons/property-safe.svg"
                      alt="propert"
                    />
                    <h6>Properties</h6>
                    <p>
                      Do you ever desired to own a piece of land or buy an
                      existing property? If yes, we got you covered. Save 60%
                      and request for gap financing.
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="choice">
                    <img src="../assets/icons/energy-safe.svg" alt="energy" />
                    <h6>Renewable Energy</h6>
                    <p>
                      Are you tired of constant electricity bills? You can stop
                      being a victim to the rising cost of electricity today by
                      funding a renewable project on our platform.
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="choice">
                    <img src="../assets/icons/others-safe.svg" alt="others" />
                    <h6>Other Projects</h6>
                    <p>
                      Do you need home and kitchen appliances, pay your school
                      fees, buy a car or just to save, you can do all of these
                      and more on the SAFE platform.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="step-guide">
        <div class="conatiner-fluid">
          <div class="step-header">
            <h6>Complete your project with ease</h6>
            <h2>A step by step guide on SAFE</h2>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="steps">
                <img src="../assets/icons/cards-safe.svg" alt="card" />
                <h6>Step 1</h6>
                <p>Sign up and obtain your GTB virtual account</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="steps">
                <img src="../assets/icons/layer-safe.svg" alt="layer" />
                <h6>Step 2</h6>
                <p>Create a project and start funding</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="steps">
                <img src="../assets/icons/pen-safe.svg" alt="pen" />
                <h6>Step 3</h6>
                <p>Execute project/Payout (T & C Apply)</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="second-hero">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6 pl-sm-0 img-sm" style="margin-bottom: 40px;">
              <div class="hero-left2">
                <img src="../assets/safe/happy-safer.png" alt="safe" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="hero-right2">
                <h1>Saving is good, saving for a purpose is great.</h1>
                <p>
                  Have any projects in mind? Need to complete an ongoing
                  project? No worries! We can help make your dreams a reality.
                  All you need is to create your account, start funding and
                  request for gap-financing.
                </p>
                <div class="hero-button">
                  <button class="btn">
                    <router-link to="/signup">
                      Create My Account
                    </router-link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Ready to get started starts -->
      <div class="">
        <div class="container-fluid reomove-row">
          <div class="ready-to-start">
            <!-- <div>
                <img src="../assets/lovedby/iconsmerge.svg" alt="">
              </div> -->
            <h4>Get Started with SAFE Today!</h4>
            <p class="col-md-7">
              Ready to start making your goals a reality? Sign up for SAFE today
              and enjoy the peace of mind that comes with easy and flexible
              payment options.
            </p>
            <div class="ready-buttons">
              <button class="btn contact">
                <router-link to="/signup">
                  Get started for free
                </router-link>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Ready to get started ends -->

      <!-- FAQ -->
      <div class="faq-holder">
        <div class="faq-top-text">
          <p>FAQ</p>
          <h3>Frequently Ask Question</h3>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <div class="d-flex align-items-start">
                <img src="../assets/icons/faq-icon.svg" alt="faq" />
                <div class="faq-list">
                  <h6>What is SAFE?</h6>
                  <p>
                    Shelta has developed a product called SAFE, which aims to
                    address the issues surrounding rent payment and financing
                    for property-related projects such as property acquisition,
                    mortgage, and more. This is done by utilizing regulated
                    institutional services and infrastructure to provide a safe
                    and secure environment for these transactions.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="d-flex align-items-start">
                <img src="../assets/icons/faq-icon.svg" alt="faq" />
                <div class="faq-list">
                  <h6>What should I use SAFE for?</h6>
                  <p>
                    Pay your rent as you want (weekly, monthly, quarterly, etc)
                    Acquire properties (Land, houses, shops, mortgage, etc.) by
                    paying as you can. Gain valuable insights into real estate
                    space, and money management. Connect with like-minded
                    individuals, industry professionals and potential business
                    partners during SAFE events.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="d-flex align-items-start">
                <img src="../assets/icons/faq-icon.svg" alt="faq" />
                <div class="faq-list">
                  <h6>What is Virtual account?</h6>
                  <p>
                    Your Virtual account is a Project account powered by GTBank
                    Squad, managed by Shelta.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="d-flex align-items-start">
                <img src="../assets/icons/faq-icon.svg" alt="faq" />
                <div class="faq-list">
                  <h6>What is Gap-Financing?</h6>
                  <p>
                    Gap-Financing service enables all community members to
                    assess the interim financing for the immediate project. A
                    member must have been committed to their chosen plan for the
                    required project and after meeting the minimum P.I can
                    request for a pay out and the project will be funded 100%
                    while allowing them complete their plan with or without
                    interest.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="d-flex align-items-start">
                <img src="../assets/icons/faq-icon.svg" alt="faq" />
                <div class="faq-list">
                  <h6>When can I fund my SAFE account?</h6>
                  <p>
                    Scheduled (Daily, Weekly, Monthly and Quarterly). Random
                    (Funding SAFE at will).
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="d-flex align-items-start">
                <img src="../assets/icons/faq-icon.svg" alt="faq" />
                <div class="faq-list">
                  <h6>How long do I need to save for my project?</h6>
                  <p>
                    The minimum duration for a project is 3 months, (the
                    duration of SAFE plan is flexible but not less than 3
                    months).
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="d-flex align-items-start">
                <img src="../assets/icons/faq-icon.svg" alt="faq" />
                <div class="faq-list">
                  <h6>What is CS (Credit Score)?</h6>
                  <p>
                    Credit score for SAFErs is the numerical representation of
                    SAFErs creditworthiness. It is computed based on the SAFErs
                    commitment and frequency in funding thier project
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="d-flex align-items-start">
                <img src="../assets/icons/faq-icon.svg" alt="faq" />
                <div class="faq-list">
                  <h6>What is APF (Accumulated Project fund)?</h6>
                  <p>
                    The accumulated project fund is the sum of all the funds
                    that the user has contributed over time through scheduled
                    funding towards their project goal, which has resulted in a
                    total amount of funds. This reflects the user’s financial
                    commitment towards meeting their goals.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="d-flex align-items-start">
                <img src="../assets/icons/faq-icon.svg" alt="faq" />
                <div class="faq-list">
                  <h6>What is PI (Performance Index)?</h6>
                  <p>
                    Performance Index is automated and calculated for each user
                    and project based on the updating credit score on the
                    system. Frequency and commitment to following user’s created
                    project plan is very important and as such defaults grossly
                    affects user’s P.I
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="d-flex align-items-start">
                <img src="../assets/icons/faq-icon.svg" alt="faq" />
                <div class="faq-list">
                  <h6>When do I access my referral bonus?</h6>
                  <p>
                    You can only access your referral bonus after completing
                    your project funding.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- FAQ -->
    </div>
    <FooterMain />
  </div>
</template>

<script>
import Header from "@/views/Header.vue";
import FooterMain from "@/views/FooterMain.vue";
import { Service } from "../store/service";
const Api = new Service();
export default {
  name: "SafeHome",
  // props: {
  //   properties
  // },

  components: {
    FooterMain,
    Header
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$off-grey: #f5f7fe;
$off-white: #f9f9f9;
$shelta-black: #000a2f;
$light-grey: #555c74;
$white: #ffffff;

@font-face {
  font-family: "Lato-Bold";
  src: local("Lato-Bold"),
    url("../assets/fonts/Lato/Lato-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"),
    url("../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-light";
  src: local("Lato-Light"),
    url("../assets/fonts/Lato/Lato-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-bold-Italic";
  src: local("Lato-BlackItalic"),
    url("../assets/fonts/Lato/Lato-BlackItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-thin-Italic";
  src: local("Lato-ThinItalic"),
    url("../assets/fonts/Lato/Lato-ThinItalic.ttf") format("truetype");
}

.holder {
  margin-top: 7rem;
}

.hero-left {
  text-align: left;
  padding-left: 20px;
  h1 {
    margin: 25% auto 16px;
    color: $shelta-black;
    font-family: Lato;
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    text-align: left;
    // padding-left: 20px;
  }
  p {
    color: $light-grey;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 24px;
  }
  .hero-button {
    button {
      background: $primary;
      border-radius: 6px;
      padding-left: 16px;
      padding-right: 16px;
      color: $white;
      text-align: center;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 125%;
      height: 56px;
      outline: 0;
      box-shadow: none;
      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }
}

.hero-right {
  img {
    width: 100%;
  }
}

@media only screen and (max-width: 599px) {
  .hero-left {
    h1 {
      font-size: 32px;
      margin-top: 40px;
    }
  }
}

.project-choice {
  // margin-top: 100px;
  padding: 64px 20px;
  background: $off-grey;
}
.choice-header {
  text-align: left;
  h2 {
    color: $shelta-black;
    font-family: Lato;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
  }
}
.choice {
  text-align: left;
  img {
    margin-bottom: 24px;
  }
  h6 {
    color: $shelta-black;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    margin-bottom: 8px;
  }
  p {
    color: $light-grey;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}

@media only screen and (max-width: 599px) {
  .project-choice {
    padding: 64px 15px;
  }
  .choice-header {
    h2 {
      font-size: 26px;
    }
  }
}

.step-guide {
  padding: 64px 40px;
}
.step-header {
  text-align: left;
  h6 {
    color: $primary;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    margin-bottom: 8px;
  }
  h2 {
    color: $shelta-black;
    font-family: Lato;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    margin-bottom: 40px;
  }
}

.steps {
  text-align: left;
  border-radius: 8px;
  background: $off-white;
  padding: 24px;
  img {
    margin-bottom: 15px;
  }
  h6 {
    color: $shelta-black;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    margin-bottom: 15px;
  }
  p {
    margin-bottom: 15px;
    color: $light-grey;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
  }
}

@media only screen and (max-width: 599px) {
  .steps {
    margin-bottom: 24px;
  }
  .step-header {
    h2 {
      font-size: 26px;
    }
  }
  .step-guide {
    padding: 64px 30px;
  }
}

.second-hero {
  margin-bottom: 50px;
}
.hero-right2 {
  text-align: left;
  padding-right: 20px;
  h1 {
    margin: 25% auto 16px;
    color: $shelta-black;
    font-family: Lato;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    text-align: left;
    // padding-left: 20px;
  }
  p {
    color: $light-grey;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 24px;
  }
  .hero-button {
    button {
      background: $primary;
      border-radius: 6px;
      padding-left: 16px;
      padding-right: 16px;
      color: $white;
      text-align: center;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 125%;
      height: 56px;
      outline: 0;
      box-shadow: none;
      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }
}

.hero-left2 {
  padding-left: 0;
  img {
    width: 100%;
  }
}

@media only screen and (max-width: 599px) {
  .hero-right2 {
    h1 {
      font-size: 32px;
      margin-top: 40px;
    }
  }
  .img-sm {
    padding: 0;
  }
}

// / ready to start begins
.ready-to-start {
  // background-color: $primary;
  background: url("../assets/lovedby/readytostart.svg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 320px;
  padding: 40px 77px 0px 77px;
  margin: 0 20px 50px;
  position: relative;
  h4 {
    text-align: center;
    font-family: Lato;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    color: $white;
    margin-bottom: 16px;
  }
  p {
    margin: auto;
    color: $white;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}
.ready-buttons {
  button {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
    text-align: center;
    width: 183px;
    height: 56px;
    border-radius: 6px;
    margin: 24px 20px 20px;
    box-shadow: none;
    outline: 0;
    a {
      color: inherit;
    }
  }
  .contact {
    color: $primary;
    background-color: $white;
    a {
      color: inherit;
      text-decoration: none;
    }
  }
}

@media only screen and (max-width: 599px) {
  .reomove-row {
    padding-left: 0;
    padding-right: 0;
  }
  .ready-to-start {
    margin: 0 0px 30px;
    padding: 30px 0 20px 0;
    height: auto;
    h4 {
      font-size: 28px;
    }
  }
  .ready-buttons {
    display: flex;
    margin: 30px 20px;
    .contact {
      margin-right: 10px;
    }
    button {
      // display: flex;
      width: 100%;
      margin: 0;
    }
  }
}
// Ready to starts ends

// FAQ

.faq-holder {
  margin-top: 64px;
  margin-bottom: 64px;
  margin-left: 20px;
  margin-right: 20px;
}
.faq-top-text {
  p {
    color: $primary;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 124%;
  }
  h3 {
    color: $shelta-black;
    text-align: center;
    font-family: Lato;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 124%;
    margin-bottom: 80px;
  }
}

.faq-list {
  text-align: left;
  margin-bottom: 40px;
  padding-left: 32px;
  h6 {
    color: $shelta-black;
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
  }
  p {
    color: $light-grey;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}
</style>
